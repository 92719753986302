import { Observable } from 'rxjs'
import AxiosSubscriber from '../../factory/AxiosSubscriber'
import { tokenCustomer } from '../../shared/constant'

export class AuthProcoRegisterAPI {
  constructor(username) {
    this.subscriptionCustomer = null
    this.username = username
  }

  subscribeToCustomer(next, complete, error) {
    const authorization = tokenCustomer

    const httpConfig = {
      url: '/c/tokenProcoRegister',
      method: 'post',
      data: `username=${this.username}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${authorization}`,
      },
    }

    let observable$ = new Observable((observer) => {
      return new AxiosSubscriber(observer, httpConfig, false)
    })

    this.subscriptionCustomer = observable$.subscribe({
      next: next,
      complete: complete,
      error: error,
    })
  }

  unsubscribeToCustomer() {
    if (
      this.subscriptionCustomer !== undefined &&
      this.subscriptionCustomer !== null
    ) {
      this.subscriptionCustomer.unsubscribe()
    }
  }
}
