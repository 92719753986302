import React, { useContext } from 'react'

import './EmailForm.scss'
import InputField from '../../../components/Form/InputField/InputField'
import { LoginContext } from '../../../context/loginContext'

const EmailForm = ({ isAdmin, onKeyDown = null }) => {
  const loginContext = useContext(LoginContext)

  return (
    <div className="form-field-wrapp">
      <InputField
        label="Email Address"
        placeholder="Email Address"
        type="email"
        name="username"
        id="email"
        autoComplete="username"
        onKeyDown={onKeyDown}
        isRequired
        isInvalid={loginContext.email.isInvalid}
        onChange={loginContext.email.onChange}
        value={loginContext.email.value}
      />
    </div>
  )
}

export default EmailForm
