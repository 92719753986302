import React from 'react'

const SvgLogoLogin = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={164.4}
    height={42.5}
    viewBox="0 0 164.4 42.5"
    {...props}
  >
    <linearGradient
      id="prefix__a"
      gradientUnits="userSpaceOnUse"
      x1={-336.198}
      y1={312.329}
      x2={-334.507}
      y2={312.229}
      gradientTransform="matrix(164.412 0 0 -42.52 55233.25 13299.598)"
    >
      <stop offset={0.416} stopColor="#e75204" />
      <stop offset={0.429} stopColor="#df540a" />
      <stop offset={0.449} stopColor="#c95b19" />
      <stop offset={0.473} stopColor="#a66532" />
      <stop offset={0.501} stopColor="#757456" />
      <stop offset={0.532} stopColor="#378682" />
      <stop offset={0.557} stopColor="#0096a9" />
    </linearGradient>
    <path
      fill="url(#prefix__a)"
      d="M143 33.7v3l-11.2-5.8 11.2-6.2v3h21.2c-1.3-3.8-4.6-6.5-8.5-7.2 4-.9 7.3-4.5 7.3-9.8C163 5.5 159 0 150.6 0h-100l-7 24.6L35.9 0h-9.6l-7.7 24.6L11.7 0H0l13.2 42.5H23l8-24.2 7.9 24.2h9.8l10-32.2h12v32.2h11.1V10.3h23.9v32.2h11.1V10.3h25.9v-3l11.2 5.8-11.2 6.2v-3h-13.9v26.3h21.8c7.2 0 12.5-3.6 13.8-8.8l-21.4-.1z"
    />
  </svg>
)

export default SvgLogoLogin
