import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import validator from 'validator'
import update from 'immutability-helper'
import { Helmet } from 'react-helmet'
import { NavLink } from 'react-router-dom'

import './Auth.scss'
import * as actions from '../../store/actions/index'
import LoginForm from './LoginForm/LoginForm'
import EmailForm from './EmailForm/EmailForm'
import { LoginContext } from '../../context/loginContext'
import clientLogin from '../../shared/clientLogin'
import * as Router from '../../shared/router'
import branding from '../../shared/branding'
import SvgLogoLogin from '../../Icons/LogoLogin'
import SvgLogoWTTB from '../../Icons/LogoWTTB'

export const Auth = ({
  onAuth,
  onAuthProco,
  onAuthProcoResend,
  authRedirectPath,
  isAuthenticated,
  clientId,
}) => {
  const defaultValueInput = {
    value: '',
    isEmpty: false,
    isInvalid: false,
  }

  const [email, setEmail] = useState(defaultValueInput)
  const [emailRegister, setEmailRegister] = useState(defaultValueInput)
  const [password, setPassword] = useState(defaultValueInput)
  // const [heading, setHeading] = useState('')
  const [attempLogin, setAttempLogin] = useState(0)
  const [procoCheck, setProcoCheck] = useState('')
  const role = useSelector((state) => {
    return state.auth.role
  })

  useEffect(() => {
    // setHeading(`Log in to ${branding.NAME}`)
    setAttempLogin(0)
  }, [])

  useEffect(() => {
    // setProcoCheck(role)
    setProcoCheck(role)
  }, [role])

  const handleSubmitLogin = () => {
    let isValidField = true

    if (validator.isEmpty(email.value)) {
      setEmail(
        update(email, {
          isEmpty: { $set: validator.isEmpty(email.value) },
        })
      )

      isValidField = false
    }

    if (!validator.isEmpty(email.value) && !validator.isEmail(email.value)) {
      setEmail(
        update(email, {
          isInvalid: { $set: !validator.isEmail(email.value) },
        })
      )

      isValidField = false
    }

    if (validator.isEmpty(password.value)) {
      setPassword(
        update(password, {
          isEmpty: { $set: validator.isEmpty(password.value) },
        })
      )

      isValidField = false
    }

    if (isValidField) {
      onAuth(email.value, password.value, () => {
        setAttempLogin((x) => x + 1)
      })
    }
  }

  const handleSubmitRegister = () => {
    let isValidField = true

    if (validator.isEmpty(emailRegister.value)) {
      setEmail(
        update(emailRegister, {
          isEmpty: { $set: validator.isEmpty(emailRegister.value) },
        })
      )

      isValidField = false
    }

    if (
      !validator.isEmpty(emailRegister.value) &&
      !validator.isEmail(emailRegister.value)
    ) {
      setEmail(
        update(emailRegister, {
          isInvalid: { $set: !validator.isEmail(emailRegister.value) },
        })
      )

      isValidField = false
    }

    if (isValidField) {
      onAuthProco(emailRegister.value)
    }
  }
  const handleSubmitResend = () => {
    onAuthProcoResend(emailRegister.value)
  }

  let authRedirect = null
  if (isAuthenticated) {
    authRedirect = <Redirect to={authRedirectPath} />
  }

  if (authRedirect === null && clientId && clientId !== clientLogin.CUSTOMER) {
    authRedirect = <Redirect to={Router.signinAdmin} />
  }

  const loginContextValue = {
    email: {
      value: email.value,
      onChange: (e) => {
        setEmail(
          update(email, {
            value: { $set: e.target.value },
            isEmpty: { $set: validator.isEmpty(e.target.value) },
            isInvalid: {
              $set:
                !validator.isEmpty(e.target.value) &&
                !validator.isEmail(e.target.value),
            },
          })
        )
      },
      isEmpty: email.isEmpty,
      isInvalid: email.isInvalid,
    },
    password: {
      value: password.value,
      onChange: (e) => {
        setPassword(
          update(password, {
            value: { $set: e.target.value },
            isEmpty: { $set: validator.isEmpty(e.target.value) },
            isInvalid: {
              $set:
                !validator.isEmpty(e.target.value) &&
                !validator.isLength(e.target.value, { min: 6, max: 20 }),
            },
          })
        )
      },
      isEmpty: password.isEmpty,
      isInvalid: password.isInvalid,
    },
  }
  const emailContextValue = {
    email: {
      value: emailRegister.value,
      onChange: (e) => {
        setEmailRegister(
          update(emailRegister, {
            value: { $set: e.target.value },
            isEmpty: { $set: validator.isEmpty(e.target.value) },
            isInvalid: {
              $set:
                !validator.isEmpty(e.target.value) &&
                !validator.isEmail(e.target.value),
            },
          })
        )
      },
      isEmpty: emailRegister.isEmpty,
      isInvalid: emailRegister.isInvalid,
    },
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmitLogin()
    }
  }

  const handleKeyDownRegister = (e) => {
    if (e.key === 'Enter') {
      handleSubmitRegister()
    }
  }

  return (
    <div className="Auth h-100">
      {authRedirect}
      <Helmet>
        <title>{branding.LOGIN_TITLE}</title>
        <meta
          name="description"
          content="ngine delivers high quality print ready fixes and print ready artwork. get your on demand subscription graphic design services now!"
        />
        <meta
          name="keywords"
          content="login design, buy graphic design, graphic design, graphic design australia, need design, need artwork, subscription design, design service, design platform, need a logo, logo design, business identity, brand identity"
        />
      </Helmet>

      <div className="container position-relative h-100">
        {/* <div className="header">
          <div className="logo d-flex align-items-center">
            {branding.NAME === 'Artworker' && (
              <a
                href={branding.DOMAIN}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SvgLogoLogin />
              </a>
            )}
          </div>

          {branding.NAME === 'Artworker' && (
            <div className="d-flex flex-row justify-content-center align-items-center ml-auto">
              <span className="text-light nobreak text-14">
                Don't have an account ?
              </span>
              <a
                className="btn btn-lg btn-darkprimary btn-block rounded-pill text-light btn-register ml-3"
                href={branding.REGISTER}
                target="_blank"
                rel="noopener noreferrer"
              >
                Get Started
              </a>
            </div>
          )}
        </div> */}

        <div className="orbs1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="72"
            viewBox="0 0 24 24"
          >
            <path
              fill="#66C1BF"
              d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"
            />
          </svg>
        </div>

        <div className="orbs2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            viewBox="0 0 24 24"
          >
            <path
              fill="#E62154"
              d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"
            />
          </svg>
        </div>

        <div className="form-signin" id="wrapper">
          <div
            style={{ width: '50%', paddingLeft: '3rem', paddingRight: '3rem' }}
            className="wrapper-div"
          >
            <div
              className="container pb-5"
              style={{ marginLeft: '-11px', marginTop: '-8px' }}
            >
              <SvgLogoLogin width="281.25px" height="62.5px" />
            </div>
            <p>Enter your details below.</p>
            <LoginContext.Provider value={loginContextValue}>
              <LoginForm onKeyDown={handleKeyDown} />
            </LoginContext.Provider>

            <br />
            <div
              className={`text-danger text-14 ${
                attempLogin > 1 ? 'd-block' : 'd-none'
              }`}
            >
              <span>If you've forgotten your password, please click</span>
            </div>
            <NavLink to={Router.forgotPassword} className="btn btn-link">
              Forgot Password?
            </NavLink>

            <div className="d-flex flex-row justify-content-center align-items-center">
              <button
                type="button"
                className="btn btn-lg btn-darkprimary btn-block rounded-pill btn-login"
                onClick={handleSubmitLogin}
              >
                Log in
              </button>
            </div>
          </div>
          <div
            style={{
              width: '50%',
              paddingLeft: '3rem',
              paddingRight: '3rem',
              height: '420px',
            }}
            className="wrapper-div"
          >
            <div className="container pb-5">
              <SvgLogoWTTB />
            </div>

            {procoCheck ? (
              <>
                {procoCheck === 'procoExistingUser' ? (
                  <>
                    <p>
                      Success! Please check your email. We've sent the password
                      for the login to your email address.
                    </p>
                  </>
                ) : (
                  <>
                    {procoCheck === 'procoActivatedUser' ? (
                      <>
                        <p>
                          You already activated your account. Please check your
                          email. We've sent the password for the login to your
                          email address.
                          <br />
                          If you haven't received your password, please click{' '}
                          <NavLink onClick={handleSubmitResend} to="/">
                            Resend Password
                          </NavLink>
                          , or contact our customer service if the problem
                          persists.
                        </p>
                      </>
                    ) : (
                      <>
                        {procoCheck === 'procoLoggedin' ? (
                          <>
                            <p>
                              You have already logged in to your account. If you
                              forgot your password, please click{' '}
                              <NavLink to={Router.forgotPassword}>
                                Forgot Password
                              </NavLink>
                              .
                            </p>
                          </>
                        ) : (
                          <>
                            <p>
                              The email you have submitted is not registered on
                              Where The Trade Buys. Please go to their website
                              and register an account there. Thank you.
                            </p>
                            <p>https://www.wherethetradebuys.co.uk/register</p>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <p style={{ color: '#436a7b', fontSize: '11.2px' }}>
                  Do you have an account with{' '}
                  <span style={{ color: '#d06021' }}>Where The Trade Buys</span>
                  ?
                  <br />
                  Please enter your email to receive access to Artworker+
                </p>
                <LoginContext.Provider value={emailContextValue}>
                  <EmailForm onKeyDown={handleKeyDownRegister} />
                </LoginContext.Provider>

                <br />

                <div className="d-flex flex-row justify-content-center align-items-center">
                  <button
                    type="button"
                    className="btn btn-lg btn-darkprimary btn-block rounded-pill btn-login"
                    onClick={handleSubmitRegister}
                  >
                    Submit
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath,
    clientId: state.auth.clientId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (email, password, callback) =>
      dispatch(actions.auth(email, password, false, callback)),
    onAuthProco: (email) => dispatch(actions.authProco(email)),
    onAuthProcoResend: (email) => dispatch(actions.authProcoResend(email)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth)
