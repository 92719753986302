export const baseURLEndpoint = process.env.REACT_APP_BASE_URL_ENDPOINT
export const uploaderEndpoint = process.env.REACT_APP_UPLOADER_ENDPOINT
export const socketHost = process.env.REACT_APP_SOCKET_HOST
export const tokenCustomer = process.env.REACT_APP_TOKEN_CUSTOMER
export const tokenUser = process.env.REACT_APP_TOKEN_USER
// export const baseURLEndpoint = "http://localhost:3001/api/v1"
// export const customerClientID = "customer";
// export const customerSecret = "7a*d|*`)XniR/I<^J>+Z!|ST<}}p,nU`JX@Pl5P%u@c,nLlXeRT][dlb{S.*^>&3";
// export const customerEndpointPrefix = "/api/v1/c";

// export const designdeskClientID = "designdesk";
// export const designdeskSecret = "=XQ&N=3~uJk9wC6U3v8v;~=+}2xeaXkxbZFar#pJl;HCoha}dt1t7[|SZ,/_UFdn";
// export const designdeskEndpointPrefix = "/api/v1/d";

export const production = process.env.NODE_ENV === 'production'
export const defaultAvatar = process.env.REACT_APP_DEFAULT_AVATAR
export const privacyPolicyLink = process.env.REACT_APP_PRIVACY_POLICY
export const termAndConditionsLink = process.env.REACT_APP_TERM_CONDITION
export const sentryDNS = process.env.REACT_APP_SENTRY_DNS
export const tidioCode = process.env.REACT_APP_TIDIO_CODE

export const maxCreditCardLength = 16
