export const orderStatusOptions = [
  // {
  //   label: "All Status",
  //   value: '',
  // },
  {
    label: "New Order",
    value: 'ready-to-go',
  },
  {
    label: "In Progress",
    value: 'in-progress',
  },
  {
    label: "In Review",
    value: 'in-review',
  },
  {
    label: "On Hold",
    value: 'on-hold',
  },
  {
    label: "Approved",
    value: 'approved',
  },
  {
    label: "Cancelled",
    value: 'cancelled',
  },
];

export const queueTimeOptions = [
  // {
  //   label: "All Queue Time",
  //   value: '',
  // },
  {
    label: "50% or fewer",
    value: "0:50"
  },
  {
    label: "51% - 75%",
    value: "51:75",
  },
  {
    label: "76% or more",
    value: "76",
  }
];

export const workTimeOptions = [
  // {
  //   label: "All Work Time",
  //   value: '',
  // },
  {
    label: "50% or fewer",
    value: "0:50",
  },
  {
    label: "51% - 75%",
    value: "51:75",
  },
  {
    label: "76% or more",
    value: "76",
  }
];
